<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-25 17:47:27
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-10-31 20:16:11
 * @Description  : 堆叠柱状图
-->
<template>
  <div class="box">
    <div class="stacked-area-chart" ref="trend"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";

export default {
  data() {
    return {
      color: [],
      legend: [],
      xData: [],
      unit: "",
      lineSeries: [],
      dataZoom: [],
      axisLabel: {},
      y2Height: 70,
      yAxisName: "",
      yMax: 0, // 新增变量用于存储 Y 轴最大值
    };
  },
  mounted() {},
  methods: {
    init(LineData) {
      this.color = LineData.color;
      this.yAxisName = LineData.yAxis.name;
      this.legend = LineData.legend;
      this.xData = LineData.xData;
      this.axisLabel = LineData.axisLabel;
      this.unit = LineData.unit;
      this.lineSeries = LineData.lineSeries;
      this.y2Height = LineData.y2Height; //x轴文字高度

      if (LineData.dataZoom) {
        this.dataZoom = LineData.dataZoom;
      }
      this.calculateYMax(); // 计算 Y 轴最大值
      this.$nextTick(() => {
        this.initBarCharts();
      });
    },
    calculateYMax() {
      console.log("this.lineSeries: ", JSON.stringify(this.lineSeries));
      // this.lineSeries = [
      //   {
      //     name: "Loggers Without Alerts",
      //     type: "bar",
      //     stack: "total",
      //     label: { show: true },
      //     emphasis: { focus: "series" },
      //     data: [0, 6, 5, 5, 6, 6, 3, 1, 3, 4, 4, 4],
      //   },
      //   {
      //     name: "Loggers With Alerts",
      //     type: "bar",
      //     stack: "total",
      //     label: { show: true },
      //     data: [6, 0, 1, 1, 0, 0, 3, 3, 2, 0, 0, 0],
      //   },
      // ];
      let max = -Infinity; // 使用负无穷作为初始值
      const maxLength = Math.max(
        ...this.lineSeries.map((series) => series.data.length)
      );
      const summedData = new Array(maxLength).fill(0);

      // 遍历所有系列数据，累加相同位置的数据
      this.lineSeries.forEach((series) => {
        series.data.forEach((value, index) => {
          // 如果数据是多维数组，则取第一个元素（假设最大值在第一个位置）
          if (Array.isArray(value)) {
            value = value[0]; // 取数组的第一个元素
          }
          summedData[index] += value;
        });
      });

      // 找出累加后的最大值
      summedData.forEach((value) => {
        if (value > max) {
          max = value;
        }
      });
      console.log("max: ", max);

      // 以 10 为单位向上取整
      // this.yMax = Math.ceil(max / 10) * 10;

      if (max % 10 === 0) {
        // 如果最大值已经是10的倍数，设置为该值的120%
        this.yMax = max * 1.2;
      } else {
        // 如果不是10的倍数，以10为单位向上取整
        this.yMax = Math.ceil(max / 10) * 10;
      }

      console.log("this.yMax: ", this.yMax);
    },
    initBarCharts() {
      this.chart = echarts.init(this.$refs.trend);
      this.$nextTick(() => {
        this.chart.setOption({
          color: this.color,
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            left: "2%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          label: {
            show: true,
            formatter: (params) => (params.value === 0 ? "" : params.value),
          },
          toolbox: {
            feature: {
              dataView: { show: false, readOnly: true },
              restore: { show: false },
              saveAsImage: { show: false },
            },
          },
          dataZoom: this.dataZoom,
          legend: {
            show: true,
            // data: this.legend,
            // y: "bottom",
            // icon: "circle",
            top: "5%",
          },
          xAxis: {
            type: "category",
            data: this.xData,
            axisLabel: {
              interval: 0, // 显示所有类目标签
              // rotate: 45, // 旋转标签以避免重叠
            },
            axisTick: false,
          },
          yAxis: {
            type: "value",
            // name: "Total missions",
            name: this.yAxisName,
            nameLocation: "middle",
            nameGap: 35,
            min: 0, // 设置 Y 轴最小值
            max: this.yMax, // 设置 Y 轴最大值
            splitNumber: 1, // 控制 Y 轴上刻度的数量
            // axisLabel: {
            //   formatter: function (value) {
            //     if (value === 0 || value >= this.yMax - 10) {
            //       return value;
            //     }
            //     return "";
            //   },
            // },
            interval: 10, // 每隔 10 添加一条刻度线
          },
          series: this.lineSeries,
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.stacked-area-chart {
  background-color: white;
  height: 428px;
  padding: 16px 0 17px 22px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
</style>
